import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IConctatSendState } from "../model/IContactSendState";
import { IContactInfo } from "../model/IContactInfo";
import { RootState, AppDispatch } from "./store";
import { connStr } from "./global";
import { ISupportInfo } from "../model/ISupportInfo";

const initialState: IConctatSendState = {
  isSending: false,
  isSent: false,
  success: false,
};
const contactSlice = createSlice({
  name: "contactRequest",
  initialState,
  reducers: {
    setContactState: (state, action: PayloadAction<IConctatSendState>) => {
      state.isSending = action.payload.isSending;
      state.isSent = action.payload.isSent;
      state.success = action.payload.success;
    },
    resetState: (state) => {
     return initialState;
    },
  },
});

export const submitContactRequest = (request: IContactInfo) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const basUrl = connStr.contact;
      const isSending = getState().contact.isSending;
      !isSending &&
        dispatch(
          contactActions.setContactState({
            isSending: true,
            isSent: false,
            success: false,
          })
        );

      const response = await fetch(basUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(request),
        redirect: "follow",
      });

      const res = response.status;
      if (res === 200) {
        dispatch(
          contactActions.setContactState({
            isSending: false,
            isSent: true,
            success: true,
          })
        );
      } else {
        dispatch(
          contactActions.setContactState({
            isSending: false,
            isSent: true,
            success: false,
          })
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        contactActions.setContactState({
          isSending: false,
          isSent: true,
          success: false,
        })
      );
    }
  };
};

export const submitSupportRequest = (request: ISupportInfo) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const basUrl = connStr.support;
      const isSending = getState().contact.isSending;
      !isSending &&
        dispatch(
          contactActions.setContactState({
            isSending: true,
            isSent: false,
            success: false,
          })
        );

      const response = await fetch(basUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(request),
        redirect: "follow",
      });

      const res = response.status;
      if (res === 200) {
        dispatch(
          contactActions.setContactState({
            isSending: false,
            isSent: true,
            success: true,
          })
        );
      } else {
        dispatch(
          contactActions.setContactState({
            isSending: false,
            isSent: true,
            success: false,
          })
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        contactActions.setContactState({
          isSending: false,
          isSent: true,
          success: false,
        })
      );
    }
  };
};

export const contactActions = contactSlice.actions;
export default contactSlice;
