import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  FaUser,
  FaEnvelope,
  FaBuilding,
  FaListAlt,
  FaPen,
} from "react-icons/fa";
import { IContactInfo } from "./model/IContactInfo";
import { ISupportInfo } from "./model/ISupportInfo";
import { submitSupportRequest, contactActions } from "./store/contactSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { GrCloudSoftware, GrLicense } from "react-icons/gr";

const Support = () => {
  const isSending = useAppSelector((state) => state.contact.isSending);
  const isSent = useAppSelector((state) => state.contact.isSent);
  const success = useAppSelector((state) => state.contact.success);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(contactActions.resetState());
  }, [dispatch]);

  const supportSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    software: Yup.string().required("Required"),
    license: Yup.string().required("Required"),
    concern: Yup.string().required("Required"),
    message: Yup.string().required("Please leave us a message"),
  });

  const initialValues: ISupportInfo = {
    name: "",
    email: "",
    software: "",
    license: "",
    concern: "",
    message: "",
  };

  const restState = () => {
    setTimeout(() => {
      dispatch(contactActions.resetState());
    }, 5000);
  };

  const handleSubmit = (supportInfo: ISupportInfo) => {
    dispatch(submitSupportRequest(supportInfo));
  };

  const FormContent = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={supportSchema}
        onSubmit={(values, actions) => {
          handleSubmit(values);
          actions.setSubmitting(false);
        }}
      >
        {({ errors, touched }) => (
          <Form action="#" method="POST" className="max-w-4xl mx-auto">
            {/* Name field */}

            <div className="form-control">
              <label htmlFor="name" className="label">
                <span className="label-text">Your Name</span>
                {errors.name && touched.name && (
                  <div className="text-red-600 text-sm">{errors.name}</div>
                )}
              </label>
              <label className="input input-bordered flex items-center gap-2">
                <FaUser />
                <Field
                  type="text"
                  id="name"
                  name="name"
                  className="grow p-1"
                  placeholder="John Doe"
                  maxLength={500}
                />
              </label>
            </div>

            {/* Email field */}
            <div className="form-control">
              <label htmlFor="email" className="label">
                <span className="label-text">Your Email</span>
                {errors.email && touched.email && (
                  <div className="text-red-600 text-sm">{errors.email}</div>
                )}
              </label>
              <label className="input input-bordered flex items-center gap-2">
                <FaEnvelope />
                <Field
                  type="email"
                  id="email"
                  name="email"
                  className="grow p-1"
                  placeholder="john.doe@example.com"
                  maxLength={500}
                />
              </label>
            </div>
            {/* Lis of Products */}
            <div className="form-control border">
              <label htmlFor="software" className="label">
                <span className="label-text">Software</span>
                {errors.software && touched.software && (
                  <div className="text-red-600 text-sm">{errors.software}</div>
                )}
              </label>
              <label className="input  flex items-center gap-2">
                <GrCloudSoftware />
                <Field
                  as="select"
                  id="software"
                  name="software"
                  className="select  w-full"
                >
                  <option value="">
                    Please select the software you purchased
                  </option>
                  <option value="media_explorer">Windows Media Explorer</option>
                  <option value="cb_player">CB Player/Recorder</option>
                  <option value="streamopen">StreamOpen</option>
                  <option value="javexplorer">J.A.V. The Explorer</option>
                </Field>
              </label>
            </div>
            {/* Lisence field */}
            <div className="form-control">
              <label htmlFor="license" className="label">
                <span className="label-text">License Key</span>
                {errors.license && touched.license && (
                  <div className="text-red-600 text-sm">{errors.license}</div>
                )}
              </label>
              <label className="input input-bordered flex items-center gap-2">
                <GrLicense />
                <Field
                  type="text"
                  id="license"
                  name="license"
                  className="grow p-1"
                  placeholder="XXX-XXXX-XXX"
                  maxLength={500}
                />
              </label>
            </div>

            {/* Area of concer */}
            <div className="form-control border">
              <label htmlFor="interest" className="label">
                <span className="label-text">Area of Concern</span>
                {errors.concern && touched.concern && (
                  <div className="text-red-600 text-sm">{errors.concern}</div>
                )}
              </label>
              <label className="input  flex items-center gap-2">
                <FaListAlt />
                <Field
                  as="select"
                  id="concern"
                  name="concern"
                  className="select  w-full"
                >
                  <option value="">Please a support department</option>
                  <option value="billing">Billing</option>
                  <option value="software-issue">Software Issue</option>
                  <option value="subscription-cancellation">
                    Subscription Cancellation
                  </option>
                  <option value="technical-support">Technical Support</option>
                  <option value="product-inquiry">Product Inquiry</option>
                  <option value="account-management">Account Management</option>
                  <option value="feature-request">Feature Request</option>
                  <option value="feedback">Feedback</option>
                  <option value="other">Other</option>
                </Field>
              </label>
            </div>

            {/* Additional Considerations or Concerns */}
            <div className="form-control">
              <label htmlFor="message" className="label">
                <span className="label-text">Your Message</span>
                {errors.message && touched.message && (
                  <div className="text-red-600 text-sm">{errors.message}</div>
                )}
              </label>
              <Field
                as="textarea"
                id="message"
                name="message"
                rows={4}
                className="textarea textarea-bordered w-full"
                placeholder="Please share any specific details or requests here."
                maxLength={1999}
              ></Field>
            </div>

            {/* Submit button */}
            <button type="submit" className="btn btn-primary mt-4 w-full">
              Submit Request
            </button>
          </Form>
        )}
      </Formik>
    );
  };
  const ErrorContent = () => {
    return (
      <div className="text-center text-red">
        <p className="text-xl font-semibold text-error ">
          We appologize for the inconvenience
        </p>
        <p className="text-md font-semibold text-error">
          There was en error submitting your request. Please try again later...
        </p>
      </div>
    );
  };
  const SubmittedContent = () => {
    return (
      <div className="text-center">
        <p className="text-2xl font-semibold text-primary ">
          Thank you for reaching!
        </p>
        <p className="text-xl ">Your request has been sent successfully</p>
        <p>
          If you don't receive an email confirmation shortly, please verify your
          email address and try again.
        </p>
      </div>
    );
  };
  const SendingContent = () => {
    return (
      <div className="text-center">
        <div className="loading loading-bars loading-lg text-accent"></div>
        <p className="text-2xl font-semibold text-primary ">
          Sending your request...
        </p>
      </div>
    );
  };
  const content = () => {
    if (isSending && !isSent) {
      return SendingContent();
    }
    if (isSent) {
      if (success) {
        restState();
        return SubmittedContent();
      } else {
        restState();
        return ErrorContent();
      }
    }
    return FormContent();
  };

  return (
    <main className=" bg-gray-100 text-gray-900">
      <section className="bg-white text-gray-900 py-10">
        <div className="container mx-auto px-4">
          <h2 className="text-center text-3xl font-bold mb-6">
            Support request for auxiliary software
          </h2>
          <p className=" text-lg mb-8">
            If you are an existing ERP module customer, please use the dedicated
            contact channels provided at the time of your service agreement. For
            customers of our auxiliary software products listed below, kindly
            fill out the form, specifying how we can assist you.
          </p>
          {/* form */}
          {content()}
        </div>
      </section>
    </main>
  );
};

export default Support;
