import React from "react";

function Approach() {
  return (
    <div className="bg-white text-gray-800">
      <div className="container mx-auto px-4 py-10">
        {/* <h1 className="text-center text-4xl font-extrabold text-sky-950 mb-4">
          Our Approach
        </h1>
        <h3 className=" text-center text-xl font-semibold mb-8 text-sky-900">
          How We Deliver Custom Solutions
        </h3> */}

        <div className="flex flex-wrap -mx-4 text-center items-center justify-center">
          <div className="w-full md:w-1/2 xl:w-1/3 px-4 mb-8">
            <div className="px-6 py-8 shadow-lg rounded-lg bg-sky-50 border border-zinc-300">
              <h3 className="text-xl font-bold mb-4 text-primary">
                Consultation & Analysis
              </h3>
              <p className="text-gray-600 text-lg">
                Every project begins with a deep dive into your current
                operations to understand the challenges and requirements
                specific to your business. This stage is crucial for identifying
                the key areas where our ERP solutions can make the most
                significant impact.
              </p>
            </div>
          </div>

          <div className="w-full md:w-1/2 xl:w-1/3 px-4 mb-8">
            <div className="px-6 py-8 shadow-lg rounded-lg bg-sky-50 border border-zinc-300">
              <h3 className="text-xl font-bold mb-4 text-primary">
                Solution Design & Customization
              </h3>
              <p className="text-gray-600 text-lg">
                Leveraging our extensive retail experience, we design solutions
                that fit seamlessly into your business, enhancing existing
                processes and introducing new efficiencies. Our approach is
                deeply collaborative, ensuring that the final product not only
                meets but exceeds your expectations.
              </p>
            </div>
          </div>

          <div className="w-full md:w-1/2 xl:w-1/3 px-4 mb-8">
            <div className="px-6 py-8 shadow-lg rounded-lg bg-sky-50 border border-zinc-300">
              <h3 className="text-xl font-bold mb-4 text-primary">
                Implementation & Support
              </h3>
              <p className="text-gray-600 text-lg">
                We ensure smooth implementation of your custom modules, followed
                by comprehensive support and maintenance to keep your systems
                running perfectly. Our goal is to provide a seamless transition
                with minimal disruption to your ongoing operations.
              </p>
            </div>
          </div>

          <div className="w-full px-4 mb-8">
            <div className="px-6 py-8 shadow-lg rounded-lg bg-sky-100 border border-zinc-300">
              <h3 className="text-2xl font-semibold mb-4 text-primary">
                The Challenge of Finding Specialized Retail Programmers
              </h3>
              <p className="text-gray-700 text-xl">
                Finding programmers with deep expertise in retail business
                processes is notoriously difficult...
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-1/3 px-4 mb-8">
            <div className="px-6 py-8 shadow-lg rounded-lg bg-gray-50 border border-zinc-300">
              <h3 className="text-xl font-bold mb-4 text-primary">
                Industry Specific Knowledge
              </h3>
              <p className="text-gray-600 text-lg">
                Finding programmers with deep expertise in retail business
                processes is notoriously difficult. Many programmers today are
                versed in general software development but lack the nuanced
                understanding required to tackle specific retail challenges such
                as inventory dynamics, complex pricing strategies, and customer
                relationship management within an ERP context.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-1/3 px-4 mb-8">
            <div className="px-6 py-8 shadow-lg rounded-lg bg-gray-50 border border-zinc-300">
              <h3 className="text-xl font-bold mb-4 text-primary">
                Fragmented Expertise
              </h3>
              <p className="text-gray-600 text-lg">
                In the retail sector, programmers often end up specializing in
                narrow areas due to the complexity and breadth of the systems
                involved. For example, one programmer might focus solely on user
                interface design, while another concentrates on database
                management or logistics integration. This fragmentation can make
                it hard to find individuals who have a holistic understanding of
                how various elements of an ERP system should integrate and
                function together.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 xl:w-1/3 px-4 mb-8">
            <div className="px-6 py-8 shadow-lg rounded-lg bg-gray-50 border border-zinc-300">
              <h3 className="text-xl font-bold mb-4 text-primary">
                Our Competitive Edge
              </h3>
              <p className="text-gray-600 text-lg">
                At Agate Software, we have built our team to bridge this gap.
                Our programmers are not only skilled in software development but
                also have extensive experience and training in retail-specific
                business processes. This dual expertise allows us to create ERP
                solutions that are both technically sound and highly tailored to
                the specific operational needs of retail businesses.
              </p>
            </div>
          </div>

          <div className="w-full md:w-1/2 xl:w-1/3 px-4 mb-8">
            <div className="px-6 py-8 shadow-lg rounded-lg bg-gray-50 border border-zinc-300">
              <h3 className="text-xl font-bold mb-4 text-primary">
                Continuous Training and Development
              </h3>
              <p className="text-gray-600 text-lg">
                We invest heavily in continuous education and training for our
                staff. This commitment ensures that our team remains on the
                cutting edge of both technology and retail business trends,
                enabling them to provide innovative solutions that anticipate
                market changes and business needs.
              </p>
            </div>
          </div>

          <div className="w-full md:w-1/2 xl:w-1/3 px-4 mb-8">
            <div className="px-6 py-8 shadow-lg rounded-lg bg-gray-50 border border-zinc-300">
              <h3 className="text-xl font-bold mb-4 text-primary">
                Collaborative Development Process
              </h3>
              <p className="text-gray-600 text-lg">
                We work closely with your business leaders and IT staff
                throughout the development process. This collaboration ensures
                that the solutions we develop are deeply integrated with your
                business logic, workflow, and strategic objectives. Our
                programmers strive to understand your business as well as you
                do, making them partners in your success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Approach;
