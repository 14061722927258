import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { ReactNode } from "react";
import { FaHome } from "react-icons/fa";
import { FaServicestack } from "react-icons/fa";
import { MdContactMail } from "react-icons/md";
import { PiStrategyBold } from "react-icons/pi";
import { GrArticle } from "react-icons/gr";
import { MdSupportAgent } from "react-icons/md";

export interface MenuItem {
  name: string;
  href: string;
  current: boolean;
  subtitle: string;
  icon: string;
}
export interface MenuItemState {
  nav: MenuItem[];
}
const initialState: MenuItemState = {
  nav: [
    {
      name: "Home",
      href: "/",
      current: true,
      subtitle: "",
      icon: "FaHome",
    },
    {
      name: "Services",
      href: "services",
      current: false,
      subtitle:
        "Tailored ERP Services Designed to Enhance Every Aspect of Your Retail Business",
      icon: "FaServicestack",
    },
    {
      name: "Our Approach",
      href: "approach",
      current: false,
      subtitle: "How We Deliver Custom Solutions",
      icon: "PiStrategyBold",
    },
    {
      name: "Article",
      href: "article",
      current: false,
      subtitle:
        "Explore Deeper: Gain Insights and Innovate Your Business Practices",
      icon: "GrArticle",
    },
    {
      name: "Contact",
      href: "contact",
      current: false,
      subtitle: "Let's Shape the Future of Your Retail Operations Together",
      icon: "MdContactMail",
    },
    {
      name: "Support",
      href: "support",
      current: false,
      subtitle: "",
      icon: "MdSupportAgent",
    },
  ],
};

const mainMenuSlice = createSlice({
  name: "mainMenu",
  initialState,
  reducers: {
    setSelectedMenu(state, action: PayloadAction<string>) {
      //state.nav.forEach((n) => (n.current = false));
      const navprev = state.nav.filter((n) => n.current === true);
      navprev && (navprev[0].current = false);
      const path = action.payload;
      let nav;
      if (path.length === 0) {
        nav = state.nav.filter((n) => n.name === "Home")[0];
      } else {
        nav = state.nav.filter((n) => n.href === path)[0];
      }
      nav && (nav.current = true);
    },
  },
});
export const mainMenuActions = mainMenuSlice.actions;
export default mainMenuSlice;
