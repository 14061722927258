import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <main className=" bg-gray-100 text-gray-900">
      {/* Hero Section */}
      <section className="shadow-xl rounded-lg text-center bg-[#22222f] text-white p-5">
        <div className="flex flex-col md:flex-row items-center justify-around">
          <img
            src="/AgateLogoWholeSM.jpg"
            alt="Agate Software Logo"
            className="w-[250px] h-[250px] mb-4 md:mb-0"
          />
          <div>
            <h1 className="text-[72px] font-tangerine text-cyan-300">
              Agate Software
            </h1>
            <p className="text-xl mt-4 text-cyan-400">
              Enhancing Your ERP System with Custom Solutions
            </p>
            <p className="mt-4 mx-auto leading-relaxed max-w-2xl">
              Over 15 years of retail and IT expertise converging to
              revolutionize your ERP systems.
            </p>
          </div>
        </div>
      </section>

      {/* Introduction Section */}
      <section className="py-10 px-4 bg-white">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold text-center text-gray-800">
            Introduction
          </h2>
          <div className="flex flex-col md:flex-row justify-around items-center mt-6">
            <div className="mt-4 md:mt-0 md:w-1/2">
              <img
                src="/erp.jpg"
                alt="Introduction Visual"
                className="w-full rounded-lg shadow-lg"
              />
            </div>
            <div className="md:w-1/2 ml-8">
              <p className="text-lg font-semibold text-gray-700">
                Transitioning to a new ERP system often means significant
                investment and extensive setup. Our modular enhancements
                integrate seamlessly, extending your system’s capabilities
                without the overhaul costs.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="py-10 px-4 bg-gray-50">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-10">
            Why Choose Us
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mt-6">
            <div className="bg-white rounded-lg p-4 shadow-lg border border-zinc-300 text-center">
              <h3 className="text-xl font-bold text-gray-800 mb-2">
                Customized Modules
              </h3>
              <p className="text-gray-600">
                Tailored to enhance specific ERP areas such as Inventory
                Management and Order Fulfillment.
              </p>
            </div>
            <div className="bg-sky-50 rounded-lg p-4 shadow-lg  scale-125 border border-zinc-300 text-center">
              <h3 className="text-xl font-bold text-primary mb-2">
                Preserve and Enhance
              </h3>
              <p className="text-gray-600">
                Maintain what works and improve efficiency{" "}
                <strong>without replacing your entire system.</strong>
              </p>
            </div>
            <div className="bg-white rounded-lg p-4 shadow-lg border border-zinc-300 text-center">
              <h3 className="text-xl font-bold text-gray-800 mb-2">
                Risk Minimization
              </h3>
              <p className="text-gray-600">
                Reduce the risks and the costs associated with new technology
                adoption.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Our Promise Section */}
      <section className="py-10 px-4 bg-white">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold text-center text-gray-800">
            Our Promise
          </h2>
          <p className="text-lg text-gray-700 mt-6 text-center">
            We're committed to aligning our solutions with your business goals
            and budget, ensuring seamless integration and operational
            enhancements.
          </p>
        </div>
      </section>

      {/* Get Started Section */}
      <section className="py-10 px-4 bg-gray-50">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-800">Get Started</h2>
          <p className="text-lg text-gray-700 mt-6">
            Transform your ERP capabilities without the high costs of a full
            system overhaul. Contact us to discuss your needs or schedule a
            demo.
          </p>
          <Link
            to="/contact"
            className="inline-block bg-neutral text-white font-bold py-2 px-4 mt-10 rounded hover:bg-primary transition duration-300"
          >
            Contact Us Today
          </Link>
        </div>
      </section>
    </main>
  );
};

export default Home;
