import React from "react";
import { Link } from "react-router-dom";
const ServicesPage: React.FC = () => {
  return (
    <main className="bg-gray-50 text-gray-900">
      <div className="container mx-auto px-4 py-10">
        {/* Main sections and sub-sections */}
        <div className="space-y-10">
          {/* Custom ERP Modules Section */}
          <div className="bg-sky-50 shadow-lg rounded-lg p-6 collapse collapse-arrow border border-zinc-300">
            <input type="radio" name="my-accordion-2" defaultChecked />
            <div className="collapse-title">
              <h3 className="text-2xl text-primary font-bold mb-1 ">
                Custom ERP Modules
              </h3>
              <p className="text-gray-600 mb-4 text-lg italic  font-semibold">
                Tailored for Excellence: Custom Modules to Perfectly Fit Your
                Business Needs
              </p>
            </div>

            <div className="collapse-content">
              <p className="text-gray-600 text-lg mb-4">
                At Agate Software, we specialize in developing sophisticated ERP
                modules that integrate seamlessly with your existing systems,
                enhancing functionality and addressing the common shortcomings
                identified in many standard ERP models. Our approach includes
                both add-on modules that work with your existing ERP framework
                and full replacement modules designed to overhaul specific
                functions within your system.
              </p>
              <h4 className="text-2xl text-gray-700 font-semibold mt-4 mb-3">
                Add-On Modules
              </h4>
              <p className="text-gray-600 text-lg mb-4">
                Our add-on modules are designed to act as an intermediary layer
                between users and the ERP backbone, simplifying operations and
                enhancing user experience without disrupting the underlying data
                structure. These modules are tailored to remedy the specific
                shortcomings of standard ERP models, such as inflexible product
                trees or inadequate inventory controls, which were highlighted
                in our article on ERP shortcomings. Here's how our add-on
                modules benefit your business:
              </p>
              <ul className="list-disc pl-5 space-y-2 mb-6">
                <li>
                  <strong>Seamless Integration: </strong> They integrate
                  directly with your existing ERP, leveraging its core
                  capabilities while enhancing its functionality.
                </li>
                <li>
                  <strong>User-Friendly Interface: </strong> Designed with
                  end-user efficiency in mind, these modules offer intuitive
                  interfaces that simplify complex processes and make daily
                  tasks more manageable.
                </li>
                <li>
                  <strong>Custom Functionality: </strong> Whether it’s improving
                  product hierarchy management or streamlining order processing,
                  each module is developed to address specific operational
                  deficiencies, enhancing overall efficiency and productivity.
                </li>
              </ul>
              <h4 className="text-2xl text-gray-700 font-semibold mt-4">
                Full Replacement Modules
              </h4>
              <p className="text-gray-600 text-lg my-3">
                For businesses requiring more comprehensive enhancements, we
                offer total replacement modules. These are particularly useful
                for organizations that find their current ERP systems severely
                lacking in certain areas or when the system no longer aligns
                with their business growth or operational strategy. Our
                replacement modules include:
              </p>
              <ul className="list-disc pl-5 space-y-2 mb-6">
                <li>
                  <strong>Product Management: </strong> This module completely
                  redefines how products are handled within your ERP system,
                  introducing a robust and scalable product tree and hierarchy
                  that significantly reduce redundancy and enhance data
                  accuracy.
                </li>
                <li>
                  <strong>Inventory Management: </strong> Our inventory
                  management module overhauls traditional stock systems,
                  providing sophisticated tools for tracking, analysis, and
                  optimization across all your locations, ensuring that
                  inventory levels are managed proactively to meet market
                  demands.
                </li>
                <li>
                  <strong>Order Fulfillment & Shipping: </strong> Designed to
                  work cohesively with our Product Management and Inventory
                  Management modules, this replacement module transforms how
                  orders are processed, fulfilled, and shipped. It integrates
                  advanced logistics management capabilities to streamline
                  operations from order receipt to delivery, enhancing customer
                  satisfaction.
                </li>
              </ul>
              <p className="text-gray-600 text-lg leading-relaxed mb-6">
                <Link
                  to="/contact"
                  className="font-bold text-linkColor1 hover:text-primary border border-primary rounded-lg px-2 py-1"
                >
                  Contact Agate Software
                </Link>{" "}
                today to discover how our tailored product management modules
                can revolutionize the way you manage your products and help you
                harness the full potential of your ERP system.
              </p>
            </div>
          </div>

          {/* Product Management Modules Section */}
          <div className="bg-sky-50 shadow-lg rounded-lg p-6 collapse collapse-arrow border border-zinc-300">
            <input type="radio" name="my-accordion-2" />
            <div className="collapse-title">
              <h3 className="text-2xl text-primary font-bold mb-1 ">
                Product Management Modules
              </h3>
              <p className="text-gray-600 mb-4 text-lg italic font-semibold">
                Streamlining Complexity: Transforming Product Management into a
                Competitive Advantage
              </p>
            </div>

            <div className="collapse-content">
              <p className="text-gray-600 text-lg leading-relaxed mb-6">
                For retailers, particularly those dealing with products that
                vary by color, size, or style, the structure of the product
                model can significantly influence business efficiency and
                customer satisfaction. Unfortunately, many existing ERP systems
                offer only flat or bi-level product trees, which may seem
                sufficient on the surface but fail to support the complex
                relationships between products effectively.
              </p>
              {/* Subsection for Product Tree */}
              <h4 className="text-xl text-gray-700 font-semibold my-4">
                The Product Tree
              </h4>
              <p className="text-gray-600 text-lg leading-relaxed mb-6">
                The product tree is a data design structured approach that
                maintains a hierarchical relationship between different levels
                of product details. This setup minimizes redundancy by ensuring
                that attributes common to a group of products are defined at the
                highest appropriate level. For instance, if you sell apparel,
                attributes like brand, material, and style are set at the top
                levels, while sizes and colors are defined lower in the
                hierarchy. This not only streamlines the entry and management of
                new products but also enhances data integrity across your
                product catalog.
              </p>
              {/* Subsection for Product Hierarchy */}
              <h4 className="text-xl text-gray-700 font-semibold my-4">
                The Product Hierarchy
              </h4>
              <p className="text-gray-600 text-lg leading-relaxed mb-6">
                Beyond the structured data of the product tree, the product
                hierarchy allows for logical grouping and subgrouping of
                products, facilitating better performance analysis and market
                understanding. This hierarchy impacts everything from marketing
                to stocking, enabling retailers to fine-tune their inventory and
                sales strategies based on detailed insights into product
                performance across different segments.
              </p>
              <h4 className="text-xl text-gray-700 font-semibold my-4">
                Transforming Product Management with Agate Software
              </h4>
              <p className="text-gray-600 text-lg leading-relaxed mb-6">
                At Agate Software, we recognize these challenges and have
                developed custom modules that seamlessly integrate with existing
                ERP systems, acting as a sophisticated layer between the user
                and the ERP backbone. These modules enhance and simplify
                operations, allowing retailers to:
              </p>
              <ul className="list-disc pl-5 space-y-2 mb-6">
                <li>
                  <strong>Manage Complex Inventories with Ease: </strong> Our
                  product management modules are designed to handle complex
                  product hierarchies effortlessly, enabling retailers to
                  maintain a clear overview of their inventory while reducing
                  the manual effort required for product data management.
                  Dynamic and Flexible Operations: By implementing a more
                  sophisticated product tree and integrating enhanced hierarchy
                  functionalities, retailers can swiftly respond to market
                  changes. This adaptability ensures that product listings are
                  always aligned with consumer demands and seasonal trends.
                </li>
                <li>
                  <strong>Enhanced Analytical Capabilities: </strong> With our
                  advanced product management tools, retailers gain deeper
                  insights into each product's performance. This detailed
                  analysis helps in making informed decisions about which
                  products to stock, promote, or discontinue, based on actual
                  sales data and market trends.
                </li>
                <li>
                  <strong>Reduced Costs and Increased Productivity: </strong>The
                  efficiency brought by our modules significantly cuts down on
                  the manpower and time required for data entry and management.
                  This reduction in operational costs and the increased
                  productivity ultimately boost the bottom line.
                </li>
              </ul>
              <h4 className="text-xl text-gray-700 font-semibold my-4">
                Comprehensive Solutions for Every Retailer
              </h4>
              <p className="text-gray-600 text-lg leading-relaxed mb-6">
                Whether you operate in the apparel industry, where product
                variations are vast, or in more standardized retail sectors,
                Agate Software’s product management modules offer tailored
                solutions that extend beyond basic ERP functionalities. We
                provide both add-on and full replacement modules, including
                specialized solutions for inventory management and order
                fulfillment, ensuring that your ERP system is a robust tool
                geared towards fostering business growth.
              </p>
              <p className="text-gray-600 text-lg leading-relaxed mb-6">
                For retailers seeking to enhance their ERP systems, Agate
                Software offers not just solutions but a partnership aimed at
                transforming your retail operations. Our commitment to
                integrating deep retail knowledge with cutting-edge technology
                makes us the ideal partner for any retail business aiming to
                optimize their product management practices and overall
                operational efficiency.
              </p>
              <p className="text-gray-600 text-lg leading-relaxed mb-6">
                <Link
                  to="/contact"
                  className="font-bold text-linkColor1 hover:text-primary border border-primary rounded-lg px-2 py-1"
                >
                  Contact Agate Software
                </Link>{" "}
                today to discover how our tailored product management modules
                can revolutionize the way you manage your products and help you
                harness the full potential of your ERP system.
              </p>
              <Link
                to="/article"
                className="inline-block bg-neutral text-white font-bold py-2 px-4 mt-3 rounded hover:bg-primary transition duration-300"
              >
                Learn More About Product Management
              </Link>
            </div>
          </div>

          {/* Inventory Management Modules Section */}
          <div className="bg-sky-50 shadow-lg rounded-lg p-6 collapse collapse-arrow border border-zinc-300">
            <input type="radio" name="my-accordion-2" />
            <div className="collapse-title">
              <h3 className="text-2xl text-primary font-bold mb-1 ">
                Inventory Management Modules
              </h3>
              <p className="text-gray-600 mb-4 text-lg italic font-semibold">
                Our inventory management solutions go beyond simple tracking.
              </p>
            </div>

            <div className="collapse-content">
              <p className="text-gray-600 text-lg leading-relaxed mb-6">
                At Agate Software, we believe that effective inventory
                management transcends simple arithmetic and basic stock-keeping
                functions. Our approach to inventory management is holistic,
                focusing not just on tracking and recording stock movements but
                on optimizing the entire inventory lifecycle and making it
                respond dynamically to market demands. Here's how our inventory
                management modules transform this crucial business function:
              </p>
              <h4 className="text-2xl text-gray-700 font-semibold my-4">
                Advanced Inventory Dynamics
              </h4>
              <p className="text-gray-600 text-lg leading-relaxed mb-6">
                Inventory management at Agate Software isn't about mere
                aggregation of incoming and outgoing stock. Yes, tracking items
                sold, returned, or adjusted for defects and theft is
                foundational, but true inventory management involves deeper
                insights and controls:
              </p>
              <ul className="list-disc pl-5 space-y-2 mb-6">
                <li>
                  <strong>Understanding Market Dynamics: </strong> We enable
                  businesses to grasp and respond to market demands swiftly and
                  efficiently. It’s about forecasting accurately, reducing
                  overstocks or stockouts, and ensuring inventory is always
                  aligned with market trends.
                </li>
                <li>
                  <strong>Control and Flexibility: </strong> Our systems provide
                  the controls needed to manage inventory proactively. This
                  includes sophisticated forecasting tools that help predict
                  demand more accurately and inventory distribution strategies
                  that ensure the right stock is in the right place at the right
                  time.
                </li>
              </ul>
              <h4 className="text-2xl text-gray-700 font-semibold my-4">
                Distribution and Allocation
              </h4>
              <p className="text-gray-600 text-lg leading-relaxed mb-6">
                The traditional ERP systems often fall short in managing the
                complexities of modern retail, especially for businesses with
                multiple sales points:
              </p>
              <ul className="list-disc pl-5 space-y-2 mb-6">
                <li>
                  <strong>Optimized Distribution Mechanisms: </strong> Unlike
                  standard ERP solutions that manage inventory transfers on a
                  cumbersome, error-prone item-by-item basis, Agate Software’s
                  modules streamline this process. Our intelligent distribution
                  tools allow for bulk actions and automatic adjustments based
                  on real-time data, significantly reducing manual effort and
                  the potential for errors.
                </li>
                <li>
                  <strong>Real-Time Inventory Management: </strong> We employ
                  advanced techniques to manage 'in transit' inventory and
                  ensure accurate stock levels across all locations. This allows
                  for seamless integration between physical stores and online
                  platforms, ensuring that every piece of inventory is accounted
                  for and visible in real-time.
                </li>
              </ul>
              <h4 className="text-2xl text-gray-700 font-semibold my-4">
                Smart Allocation and Maximization
              </h4>
              <p className="text-gray-600 text-lg leading-relaxed mb-6">
                Our inventory management goes beyond static allocation based on
                warehouse capacity or location:
              </p>
              <ul className="list-disc pl-5 space-y-2 mb-6">
                <li>
                  <strong>Dynamic Inventory Allocation: </strong> Agate
                  Software’s ERP modules are designed to dynamically allocate
                  inventory not just based on current stock levels but also
                  incorporating sales performance, demographic trends, and
                  regional demands. This proactive approach ensures that your
                  inventory distribution is always optimized for current market
                  conditions.
                </li>
                <li>
                  <strong>Interactive Distribution Maps: </strong> Simplify
                  complex logistics with visual tools that offer an intuitive
                  understanding of inventory distribution. Our systems allow
                  managers to visualize the entire distribution network, making
                  informed decisions with just a few clicks, thus enhancing
                  productivity and decision-making.
                </li>
              </ul>
              <h4 className="text-2xl text-gray-700 font-semibold my-4">
                Enhanced Fulfillment Strategies
              </h4>
              <p className="text-gray-600 text-lg leading-relaxed mb-6">
                To address the challenges of order fulfillment that often plague
                traditional ERPs, our modules integrate sophisticated
                fulfillment strategies that are tailored to the needs of modern
                retail:
              </p>
              <ul className="list-disc pl-5 space-y-2 mb-6">
                <li>
                  <strong>Unified Inventory View: </strong> Rather than managing
                  inventory in silos, our system presents a unified view that
                  encompasses all retail points and warehouses, thus leveraging
                  the full potential of your inventory and avoiding dormant
                  stock.
                </li>
                <li>
                  <strong>Intelligent Replenishment: </strong> Our replenishment
                  systems are not static; they adjust based on real-time sales
                  data, ensuring that each store is stocked optimally according
                  to both past performance and predicted future sales.
                </li>
              </ul>
              <p className="text-gray-600 text-lg leading-relaxed mb-6">
                By implementing Agate Software’s inventory management modules,
                retailers can not only manage their stock more efficiently but
                can also transform their inventory into a strategic asset that
                enhances operational efficiency, reduces costs, and improves
                customer satisfaction through better product availability and
                smarter fulfillment practices.
              </p>
              <p className="text-gray-600 text-lg leading-relaxed mb-6">
                <Link
                  to="/contact"
                  className="font-bold text-linkColor1 hover:text-primary border border-primary rounded-lg px-2 py-1"
                >
                  Contact Agate Software
                </Link>{" "}
                today to discover how our tailored product management modules
                can revolutionize the way you manage your products and help you
                harness the full potential of your ERP system.
              </p>
            </div>
          </div>

          {/* Order Fulfillment & Shipping Section */}
          <div className="bg-sky-50 shadow-lg rounded-lg p-6 collapse collapse-arrow border border-zinc-300">
            <input type="radio" name="my-accordion-2" />
            <div className="collapse-title">
              <h3 className="text-2xl text-primary font-bold mb-1">
                Order Fulfillment & Shipping
              </h3>
              <p className="text-gray-600 mb-4 text-lg italic font-semibold">
                Seamless Delivery: Enhancing Efficiency from Cart to Customer
              </p>
            </div>

            <div className="collapse-content">
              <p className="text-gray-600 text-lg leading-relaxed mb-6">
                At Agate Software, we recognize the complexity and critical
                nature of efficient order fulfillment and shipping operations in
                today's retail landscape. Our ERP solutions are meticulously
                designed to streamline these processes, ensuring that our
                clients can meet their customers' expectations with speed and
                accuracy. Here’s how we enhance these crucial areas:
              </p>
              <ul className="list-disc pl-5 space-y-2 mb-6">
                <li>
                  <strong>Real-Time Website Integration: </strong> We integrate
                  directly with your e-commerce platform to enable real-time
                  fulfillment capabilities. Managing online orders effectively
                  is not a trivial task, especially when dealing with high
                  volumes and the expectation of immediate confirmation and
                  processing. Our systems ensure that your online presence is
                  fully synced with your inventory and order management systems,
                  providing up-to-the-minute accuracy on product availability
                  and order status.
                </li>
                <li>
                  <strong>Unified Inventory Across Store Chains: </strong>{" "}
                  Unlike traditional systems where online stores might rely on a
                  single warehouse for inventory counts, our ERP solutions unify
                  the inventory across your entire store chain. This approach
                  presents your total inventory as a single, cohesive entity,
                  allowing you to leverage the full potential of your stock. By
                  doing so, we minimize dormant stock and enhance the efficiency
                  of inventory distribution, ensuring that no item remains
                  unused or forgotten.
                </li>
                <li>
                  <strong>Dynamic Source Allocation: </strong>Our innovative
                  in-store fulfillment apps include capabilities for dynamic
                  source allocation. This feature allows for the rotation of
                  source allocation based on various criteria, such as stock
                  availability, geographic proximity, or delivery times. If one
                  store is unable to fulfill an internal order, the system
                  automatically reallocates the order to another location that
                  can meet the customer’s needs without delay.
                </li>
                <li>
                  <strong>Enhanced Shipping Logistics: </strong>We integrate
                  sophisticated shipping logistics into our ERP systems that can
                  handle complex requirements, including multiple shipping
                  options, handling returns, and international logistics. This
                  integration ensures that shipping processes are not only
                  faster but also more cost-effective.
                </li>
                <li>
                  <strong>Advanced Reporting and Analytics: </strong>Our systems
                  provide detailed insights into fulfillment processes, allowing
                  you to track performance, identify bottlenecks, and optimize
                  operations. These analytics help you understand order
                  patterns, peak periods, and customer buying behaviors,
                  enabling you to make informed decisions to improve service and
                  efficiency.
                </li>
                <li>
                  <strong>Customer Satisfaction Focus: </strong>Ultimately, our
                  order fulfillment and shipping enhancements are designed to
                  improve customer satisfaction. By ensuring that orders are
                  processed quickly, inventory levels are accurate, and products
                  are delivered on time, we help you build trust and reliability
                  with your customers, which is essential for repeat business
                  and long-term success.
                </li>
              </ul>
              <p className="text-gray-600 text-lg leading-relaxed mb-6">
                By expanding and enhancing your order fulfillment and shipping
                capabilities with Agate Software’s ERP solutions, your business
                can achieve not only operational excellence but also a
                significant competitive advantage in the retail market. Our goal
                is to ensure that every aspect of your supply chain and customer
                service is optimized, seamless, and responsive to the demands of
                modern consumers.
              </p>
              <p className="text-gray-600 text-lg leading-relaxed mb-6">
                <Link
                  to="/contact"
                  className="font-bold text-linkColor1 hover:text-primary border border-primary rounded-lg px-2 py-1"
                >
                  Contact Agate Software
                </Link>{" "}
                today to discover how our tailored product management modules
                can revolutionize the way you manage your products and help you
                harness the full potential of your ERP system.
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ServicesPage;
