import { Route, Routes } from "react-router-dom";
import RootLayout from "./RootLayout";
import Home from "./Home";
import ModularDev from "./ModularDev";
import ProductMangmt from "./ProductMangmt";
import InventoryManagement from "./InventoryManagement";
import Contact from "./Contact";
import Services from "./Services";
import Approach from "./Approach";
import Article from "./Article";
import Support from "./Support";

function App() {
  return (
    <Routes>
      <Route path="/" element={<RootLayout />}>
        <Route index element={<Home />} />
        <Route path="home/*" element={<Home />} />
        <Route path="services/*" element={<Services />} />
        <Route path="approach/*" element={<Approach />} />
        <Route path="article/*" element={<Article />} />
        <Route path="contact/*" element={<Contact />} />
        <Route path="support/*" element={<Support />} />
      </Route>
      <Route path="*" element={<RootLayout />} />
    </Routes>
  );
}

export default App;
