import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  FaUser,
  FaEnvelope,
  FaBuilding,
  FaListAlt,
  FaPen,
} from "react-icons/fa";
import { IContactInfo } from "./model/IContactInfo";
import { submitContactRequest, contactActions } from "./store/contactSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./store/hooks";

const Contact = () => {
  const isSending = useAppSelector((state) => state.contact.isSending);
  const isSent = useAppSelector((state) => state.contact.isSent);
  const success = useAppSelector((state) => state.contact.success);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(contactActions.resetState());
  }, [dispatch]);

  const contactSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    companyName: Yup.string().nullable(),
    interests: Yup.string().nullable(),
    message: Yup.string().required("Please leave us a message"),
  });

  const initialValues: IContactInfo = {
    name: "",
    email: "",
    companyName: "",
    interest: "Please select",
    message: "",
  };

  const restState = () => {
    setTimeout(() => {
      dispatch(contactActions.resetState());
    }, 5000);
  };

  const handleSubmit = (contactInfo: IContactInfo) => {
    dispatch(submitContactRequest(contactInfo));
  };

  const FormContent = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={contactSchema}
        onSubmit={(values, actions) => {
          handleSubmit(values);
          actions.setSubmitting(false);
        }}
      >
        {({ errors, touched }) => (
          <Form action="#" method="POST" className="max-w-4xl mx-auto">
            {/* Name field */}

            <div className="form-control">
              <label htmlFor="name" className="label">
                <span className="label-text">Your Name</span>
                {errors.name && touched.name && (
                  <div className="text-red-600 text-sm">{errors.name}</div>
                )}
              </label>
              <label className="input input-bordered flex items-center gap-2">
                <FaUser />
                <Field
                  type="text"
                  id="name"
                  name="name"
                  className="grow p-1"
                  placeholder="John Doe"
                  maxLength={500}
                />
              </label>
            </div>

            {/* Email field */}
            <div className="form-control">
              <label htmlFor="email" className="label">
                <span className="label-text">Your Email</span>
                {errors.email && touched.email && (
                  <div className="text-red-600 text-sm">{errors.email}</div>
                )}
              </label>
              <label className="input input-bordered flex items-center gap-2">
                <FaEnvelope />
                <Field
                  type="email"
                  id="email"
                  name="email"
                  className="grow p-1"
                  placeholder="john.doe@example.com"
                  maxLength={500}
                />
              </label>
            </div>

            {/* Company field */}
            <div className="form-control">
              <label htmlFor="company" className="label">
                <span className="label-text">Company Name</span>
                {errors.companyName && touched.companyName && (
                  <div className="text-red-600 text-sm">
                    {errors.companyName}
                  </div>
                )}
              </label>
              <label className="input input-bordered flex items-center gap-2">
                <FaBuilding />
                <Field
                  type="text"
                  id="companyName"
                  name="companyName"
                  className="grow p-1"
                  placeholder="Doe Industries"
                  maxLength={500}
                />
              </label>
            </div>

            {/* Area of Interest */}
            <div className="form-control border">
              <label htmlFor="interest" className="label">
                <span className="label-text">Area of Interest</span>
                {errors.interest && touched.interest && (
                  <div className="text-red-600 text-sm">{errors.interest}</div>
                )}
              </label>
              <label className="input  flex items-center gap-2">
                <FaListAlt />
                <Field
                  as="select"
                  id="interest"
                  name="interest"
                  className="select  w-full"
                >
                  <option value="">Please select</option>
                  <option value="custom_modules">Custom ERP Modules</option>
                  <option value="full_replacement">
                    Full Module Replacement
                  </option>
                  <option value="product_management">
                    Product Management Modules
                  </option>
                  <option value="inventory_management">
                    Inventory Management Modules
                  </option>
                  <option value="order_fulfillment">
                    Order Fulfillment Modules
                  </option>
                  <option value="other">Other</option>
                </Field>
              </label>
            </div>

            {/* Additional Considerations or Concerns */}
            <div className="form-control">
              <label htmlFor="message" className="label">
                <span className="label-text">
                  Additional Considerations or Concerns
                </span>
                {errors.message && touched.message && (
                  <div className="text-red-600 text-sm">{errors.message}</div>
                )}
              </label>
              <Field
                as="textarea"
                id="message"
                name="message"
                rows={4}
                className="textarea textarea-bordered w-full"
                placeholder="Please share any specific details or requests here."
                maxLength={1999}
              ></Field>
            </div>

            {/* Submit button */}
            <button type="submit" className="btn btn-primary mt-4 w-full">
              Submit Inquiry
            </button>
          </Form>
        )}
      </Formik>
    );
  };
  const ErrorContent = () => {
    return (
      <div className="text-center text-red">
        <p className="text-xl font-semibold text-error ">
          We appologize for the inconvenience
        </p>
        <p className="text-md font-semibold text-error">
          There was en error submitting your request. Please try again later...
        </p>
      </div>
    );
  };
  const SubmittedContent = () => {
    return (
      <div className="text-center">
        <p className="text-2xl font-semibold text-primary ">
          Thank you for reaching!
        </p>
        <p className="text-xl ">Your request has been sent successfully</p>
        <p>
          If you don't receive an email confirmation shortly, please verify your
          email address and try again.
        </p>
      </div>
    );
  };
  const SendingContent = () => {
    return (
      <div className="text-center">
        <div className="loading loading-bars loading-lg text-accent"></div>
        <p className="text-2xl font-semibold text-primary ">
          Sending your request...
        </p>
      </div>
    );
  };
  const content = () => {
    if (isSending && !isSent) {
      return SendingContent();
    }
    if (isSent) {
      if (success) {
        restState();
        return SubmittedContent();
      } else {
        restState();
        return ErrorContent();
      }
    }
    return FormContent();
  };

  return (
    <main className=" bg-gray-100 text-gray-900">
      <section className="bg-white text-gray-900 py-10">
        <div className="container mx-auto px-4">
          <h2 className="text-center text-3xl font-bold mb-6">Contact Us</h2>
          <p className="text-center text-lg mb-1">
            Ready to Transform Your ERP Experience? Let's Get Started!
          </p>
          <p className="text-center text-sm mb-8 ">
            If you are an existing ERP module customer, please use the dedicated
            contact channels provided at the time of your service agreement.
          </p>
          {/* form */}
          {content()}
        </div>
      </section>
    </main>
  );
};

export default Contact;
