import React, { Fragment, useState } from "react";
import { Link, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import {
  MenuItem,
  MenuItemState,
  mainMenuActions,
} from "./store/mainMenuSlice";
import { FaHome } from "react-icons/fa";
import { FaServicestack } from "react-icons/fa";
import { MdContactMail } from "react-icons/md";
import { PiStrategyBold } from "react-icons/pi";
import { GrArticle } from "react-icons/gr";
import { MdSupportAgent } from "react-icons/md";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
const iconMap = {
  FaHome: FaHome,
  FaServicestack: FaServicestack,
  PiStrategyBold: PiStrategyBold,
  GrArticle: GrArticle,
  MdContactMail: MdContactMail,
  MdSupportAgent: MdSupportAgent,
};
interface MenuIconProps {
  item: MenuItem; // Assuming MenuItem is the correct type
}
const MenuIcon: React.FC<MenuIconProps> = ({ item }) => {
  const IconComponent = iconMap[item.icon as keyof typeof iconMap];
  if (!IconComponent) {
    return null; // Handle case where icon is not found in the map
  }
  return <IconComponent fontSize={22} className="mr-1.5 -translate-y-0.5" />;
};

const RootLayout = () => {
  const navigation = useAppSelector((state) => state.mainMenu.nav);
  const dispatch = useAppDispatch();
  const selectedMenu = useAppSelector((state) =>
    state.mainMenu.nav.find((nav) => nav.current === true)
  );
  const supportMenu = useAppSelector((state) =>
    state.mainMenu.nav.find((item) => item.name === "Support")
  );

  let location = useLocation();
  React.useEffect(() => {
    // Google Analytics
    const path = location.pathname.slice(1);
    dispatch(mainMenuActions.setSelectedMenu(path));
  }, [location]);

  return (
    <Fragment>
      <div className="min-h-full">
        <div className="bg-gray-800 pb-32">
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="border-b border-gray-700">
              <div className="flex h-16 items-center justify-between px-4 sm:px-0">
                <div className="flex items-center w-full">
                  <img
                    src="/AgateLogoSimpleSM.jpg"
                    alt="Agate Software Logo"
                    className="h-10 "
                  />
                  <h1 className=" text-4xl mr-8 font-tangerine text-cyan-300 text-nowrap">
                    Agate Software
                  </h1>

                  <div className="flex items-baseline space-x-4 w-full px-3">
                    {navigation.map(
                      (item) =>
                        item.name !== "Support" && (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              item.current
                                ? "bg-gray-900 text-white"
                                : "text-gray-300 hover:bg-gray-700 hover:text-white",
                              "px-3 py-2 rounded-md text-sm font-medium flex"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            <span>
                              <MenuIcon key={item.name} item={item}></MenuIcon>
                            </span>
                            {item.name}
                          </Link>
                        )
                    )}
                    <div className="grow"> </div>
                    {supportMenu && (
                      <Link
                        to="/support"
                        className={classNames(
                          supportMenu?.current
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "px-3 py-2 rounded-md text-sm font-medium self-end flex"
                        )}
                        aria-current={supportMenu?.current ? "page" : undefined}
                      >
                        <span>
                          <span>
                            <MenuIcon
                              key={supportMenu.name}
                              item={supportMenu}
                            ></MenuIcon>
                          </span>
                        </span>
                        {supportMenu?.name}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {selectedMenu?.name !== "Home" && (
            <header className="py-10">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h1 className="text-center text-3xl font-bold tracking-tight text-white">
                  {selectedMenu?.name}
                </h1>
                <h3 className=" text-center text-xl font-semibold  text-cyan-300">
                  {selectedMenu?.subtitle}
                </h3>
              </div>
            </header>
          )}
        </div>

        <main className="-mt-32">
          <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6 flex flex-col justify-center">
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </Fragment>
  );
};

export default RootLayout;
